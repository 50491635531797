import React from 'react';
import { StyleSheet, View } from 'react-native';

const GapWrapList = props => {
  const defaultGap = 20;
  const justifyContent = [
    "flex-start",
    "flex-end",
    "center",
    "space-between",
    "space-around",
    "space-evenly",
  ].includes(props.justifyContent) ? justifyContent : "flex-start";
  const forceItemSingleRow = !!props.forceItemSingleRow; // 一个节点一行
  const gap = isNaN(+props.gap) ? defaultGap : +props.gap;
  const gapVertical = isNaN(+props.gapVertical) ? gap : +props.gapVertical;
  const gapHorizontal = isNaN(+props.gapHorizontal) ? gap : +props.gapHorizontal;

  return (
    <View style={props.style}>
      <View
        style={[styles.fluid, {
          marginTop: -gapVertical,
          marginLeft: -gapHorizontal,
          justifyContent,
        }]}
      >
        {
          React.Children.map(props.children, (child, index) => {
            if (React.isValidElement(child)) {
              const wrapStyle = {
                marginTop: gapVertical,
                marginLeft: gapHorizontal,
              };
              if (forceItemSingleRow) {
                wrapStyle.width = "100%";
                wrapStyle.paddingRight = gapHorizontal; // 补偿负margin
              }
              return (
                <View key={`${index}`} style={wrapStyle} >{child}</View>
              );
            }
            return child;
          })
        }
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  fluid: {
    flex: 1,
    position: "relative",
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
});

export default GapWrapList;
