import React from 'react';
import { BackHandler } from 'react-native';
import { Teaset } from 'ui-m/rn';
import BasePage from './BasePage';
import NavigationBar from './NavigationBar';

function defaultPrimary() {
  return ["WEB", "MC"].includes(this.props?.app?.nativeInfo?.name);
}

export default class NavigationPage extends Teaset.NavigationPage {

  static defaultProps = {
    ...Teaset.NavigationPage.defaultProps,
    navigationBarProps: {},
  }

  static NavigationBar = NavigationBar

  renderNavigationBar = () => {
    const {
      title,
      showBackButton,
      backBtnIcon,
      renderNavigationTitle,
      renderNavigationLeftView,
      renderNavigationRightView,
      statusBarStyle,
      isInModalStack,
      modalPresentation,
      primary = defaultPrimary,
      hideBottomBorder,
    } = this.props;
    return <NavigationBar {...{
      navigation: this.props.navigation, // 实现页面切换用
      title: renderNavigationTitle === undefined ? title : renderNavigationTitle.call(this),
      showBackButton,
      backBtnIcon,
      hideBottomBorder,
      leftView: (showBackButton || renderNavigationLeftView === undefined) ? undefined : renderNavigationLeftView.call(this),
      rightView: renderNavigationRightView === undefined ? undefined : renderNavigationRightView.call(this),
      primary: typeof primary === "function" ? primary.call(this) : primary,
      statusBarStyle,
      isInModalStack,
      modalPresentation,
      ...this.props.navigationBarProps,
    }} />;
  }

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this._backHandle);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this._backHandle);
  }

  _backHandle = () => {
    if (this.props?.isGestureBackEnabled === false) {
      // REF: https://reactnative.dev/docs/backhandler
      return true;
    }
  }

  render() {
    BasePage.renderDocumentTitle(this);
    return super.render();
  }
}
