module.exports = {

  spinnerIcon: require('../assets/loading/icon-spinner.png'),
  spinnerIcon2: require('../assets/loading/icon-loading.png'),
  spinnerMini: require('../assets/loading/mini.png'),

  // 尺寸不是40x40的, 得把尺寸写到NavigationButton的iconSizeMap
  navAddBlack: require('../assets/nav/addBlack.png'),
  navAddBoldWhite: require('../assets/nav/addBoldWhite.png'),
  navAddCircleWhiteMC: require('../assets/nav/addCircleWhiteMC.png'),
  navBackBlack: require('../assets/nav/backBlack.png'),
  navBackWhite: require('../assets/nav/backWhite.png'),
  navBackWhiteMC: require('../assets/nav/backWhiteMC.png'),
  navBackBlackMCS: require('../assets/nav/backBlackMCS.png'),
  navBackBlackMS: require('../assets/nav/backBlackMS.png'),
  navCloseBlack: require('../assets/nav/closeBlack.png'),
  navCloseWhite: require('../assets/nav/closeWhite.png'),
  navCloseWhiteMC: require('../assets/nav/closeWhiteMC.png'),
  navMoreBlack: require('../assets/nav/moreBlack.png'),
  navMoreWhiteMC: require('../assets/nav/moreWhiteMC.png'),
  navMoreBlackMCS: require('../assets/nav/moreBlackMCS.png'),
  navMoreBlackMS: require('../assets/nav/moreBlackMS.png'),
  navMoreHollowWhiteMC: require('../assets/nav/moreHollowWhiteMC.png'),
  navMoreHollowBlackMCS: require('../assets/nav/moreHollowBlackMCS.png'),
  navConfirmWhiteMC: require('../assets/nav/comfirmWhiteMC.png'),

  // 无内容态
  listEmpty: require('../assets/empty/empty.png'),
  listEmpty2: require('../assets/empty/empty2.png'),
  lockEmpty: require('../assets/empty/lock.png'),

  // 零散图标
  infoCircle: require('../assets/common/infoCircle.png'), // component: Info
  tipCircle: require('../assets/common/tipCircle.png'),
  eye: require('../assets/common/eye.png'),
  eyeBlue: require('../assets/common/eyeBlue.png'),
  expaneBlue: require('../assets/common/expaneBlue.png'),
  menuExport: require('../assets/common/menuExport.png'),
  menuSearch: require('../assets/common/menuSearch.png'),
  ashbin: require('../assets/common/ashbin.png'),
  pc: require('../assets/common/pc.png'),
  closeSolidCircle: require('../assets/common/closeSolidCircle.png'),
  arrowWhiteDown: require('../assets/common/arrowWhiteDown.png'),
  refreshCircle: require('../assets/common/refreshCircle.png'),
  mcSortArrow: require('../assets/common/mc_sort_arrow.png'),
  searchMagnifier1: require('../assets/common/searchMagnifier1.png'),
  searchDelete1: require('../assets/common/searchDelete1.png'),
  expand: require("../assets/common/expand.png"),

  // app icon
  mc: require('../assets/appIcon/mc.png'),
  mcAndSeller: require('../assets/appIcon/mcAndSeller.png'),

  // component: ContactUs
  telephoneSmall: require('../assets/appIcon/telephoneSmall.png'),
  emailSmall: require('../assets/appIcon/emailSmall.png'),
  wechatSmall: require('../assets/appIcon/wechatSmall.png'),
  whatsappSmall: require('../assets/appIcon/whatsappSmall.png'),
  telegramSmall: require('../assets/appIcon/telegramSmall.png'),
  qrcodeSmall: require('../assets/appIcon/qrcodeSmall.png'),

  importFailed: require('../assets/importGoods/importFailed.png'),
  importPreference: require('../assets/importGoods/preference.png'),

  dashboardActivated: require('../assets/dashboard/activated.png'),
  dashboardPending: require('../assets/dashboard/pending.png'),
  loginPhone: require('../assets/common/phone.png'),
  loginDesktop: require('../assets/common/desktop.png'),
  dashboardLang: require('../assets/dashboard/laguage.png'),
  dashboardPersonal: require('../assets/dashboard/personal.png'),
  dashboardMenu: require('../assets/dashboard/menu.png'),

  // Cell icon
  cellDrag: require('../assets/cell/drag.png'),
  cellMore: require('../assets/cell/more.png'),
  // cellRefresh: require('../assets/cell/refresh.png'),
  cellCopy: require('../assets/cell/copy.png'),
  cellEdit: require('../assets/cell/edit.png'),

  // Button icon
  buttonAddCircle: require('../assets/button/addCircle.png'),

  // Input icon
  inputEyeOn: require('../assets/input/eye_on.png'),
  inputEyeOff: require('../assets/input/eye_off.png'),
  inputPackage: require('../assets/input/package.png'),

  // menu
  menuDashboardReports: require('../assets/menu/menuDashboardReports.png'),

};

