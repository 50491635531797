import { Clipboard } from 'react-native';
import { Toast, Dialog } from '@components';
import { getState } from "@globalUtils";
import kvLogService from '../services/kvlog';
import { connect } from 'react-redux';
import { Theme } from "ui-m/rn";

export const postException = kvLogService.postException;

export const openMC = (params = {}) => {
  const {
    scheme = "ASMCAdmin2",
    url = window.location.origin,
    launchParam = "",
  } = params;

  const schemeURL = `${scheme}://msStreamH5?param=` + encodeURIComponent(JSON.stringify({
    mode: "remote",
    url,
    launchParam,
    appParam: [{ "localKey": "key", "asKey": "mcKey" }, { "localKey": "lang", "asKey": "lang" }],
  }));

  window.location.href = schemeURL;
};

export const delay = (timeout = 140) => new Promise(resolve => setTimeout(resolve, timeout));

export const delayRun = (fn, time = 140) => delay(time).then(fn);

export const createAction = type => payload => ({ type, payload });

export const testLog = (...rest) => fetch("http://192.168.1.81:8976/log", {
  mode: "no-cors",
  method: "POST",
  body: rest.map(i => JSON.stringify(i)).join(""),
});

export const copyString = async string => {
  if (await Clipboard.isAvailable()) {
    await Clipboard.setString(string);
    const { i18n } = getState();
    Toast.success(i18n.map["cmn.toast.copy_success"]);
  }
};

export const copyDialogConfirm = (options = {}) => {
  const { i18n } = getState();
  const title = options.title;
  const content = options.content;
  const copyContent = options.copyContent || options.content;
  Dialog.show({
    title,
    content,
    leftBtnText: i18n.map["cmn.btn.cancel"],
    rightBtnText: i18n.map["cmn.btn.copy"],
    onLeftClick: () => { },
    onRightClick: () => copyString(copyContent),
    ...options,
  });
};

// 箭头函数可用
export const singleThreadAsyncFunction = fn => {
  let isRuning = false;

  return async (...rest) => {
    if (isRuning) {
      return;
    }
    isRuning = true;
    typeof fn === "function" && await fn(...rest);
    isRuning = false;
  };
};

export const createSharedPromise = () => {
  let _resolve, _reject;
  const promise = new Promise((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });
  return [promise, _resolve, _reject];
};

export const createAsyncQueue = () => {
  let queue = [];
  let current = null;

  const run = async () => {
    if (current || !queue.length) {
      return;
    }

    current = queue[0];
    queue.splice(0, 1);
    try {
      const res = await current.fn();
      current._resolve(res);
    } catch (error) {
      current._reject(error);
    }
    current = null;
    run();
  };

  const push = (fn, id = `${Date.now()}_${Math.random()}`) => {
    const [promise, _resolve, _reject] = createSharedPromise();
    queue = queue.reduce((_queue, i) => {
      i.id === id ? i._resolve() : _queue.push(i);
      return _queue;
    }, []);
    queue.push({ id, fn, promise, _resolve, _reject });
    run();
    return promise;
  };

  const unshift = (fn, id = `${Date.now()}_${Math.random()}`) => {
    const [promise, _resolve, _reject] = createSharedPromise();
    queue = queue.reduce((_queue, i) => {
      i.id === id ? i._resolve() : _queue.push(i);
      return _queue;
    }, []);
    queue.unshift({ id, fn, promise, _resolve, _reject });
    run();
    return promise;
  };

  const setPriority = (id, priority = 0) => {
    priority = +priority;
    const index = queue.findIndex(i => i.id === id);
    if (index === -1 || !(priority >= 0)) {
      return;
    }

    const item = queue[index];
    queue.splice(index, 1);
    if (priority > queue.length) {
      queue.push(item);
    } else {
      queue.splice(priority, 0, item);
    }
  };

  const clear = () => {
    queue.map(i => i._resolve());
    queue = [];
    current?._resolve?.();
    current = null;
  };

  return ({
    push,
    unshift,
    setPriority,
    clear,
  });
};

export const isTestEnv = () => {
  const hostname = window.location.hostname;

  return (
    hostname === "localhost" ||
    hostname === "test-ms-stream.jinjie.tech" ||
    hostname.indexOf("192.168") > -1
  );
};


export const connectPreset = items => {
  const selectorMap = {
    theme: state => {
      const nativeName = state.app.nativeInfo?.name;
      return ["MC", "MS", "MCS", "NANO"].includes(nativeName) ? nativeName : "MC";
    },
  };

  return connect(
    state => (Array.isArray(items) ? items : []).reduce((props, name) => {
      props[name] = selectorMap[name]?.(state);
      return props;
    }, {})
  );
};

export const ensureValidObject = i => (i && typeof i === "object") ? i : ({});

export const ensureArray = i => Array.isArray(i) ? i : [];

export const createHitSlop = (options) => {
  const valid = num => +num >= 0;

  if (typeof options === "number") {
    if (valid(options)) {
      return ({ top: options, right: options, bottom: options, left: options });
    }
  }

  if (typeof options === "string") {
    const arr = options.split(" ").map(i => +i);
    if (arr.findIndex(i => !valid(i)) > -1) {
      return;
    }
    if (arr.length === 1) {
      return ({ top: arr[0], right: arr[0], bottom: arr[0], left: arr[0] });
    } else if (arr.length === 2) {
      return ({ top: arr[0], right: arr[1], bottom: arr[0], left: arr[1] });
    } else if (arr.length === 3) {
      return ({ top: arr[0], right: arr[1], bottom: arr[2], left: arr[1] });
    } else if (arr.length === 4) {
      return ({ top: arr[0], right: arr[1], bottom: arr[2], left: arr[3] });
    }
  }

  if (options && typeof options === "object") {
    const ensureValid = num => valid(num) ? +num : 0;
    return ({
      top: ensureValid(options.top),
      right: ensureValid(options.right),
      bottom: ensureValid(options.bottom),
      left: ensureValid(options.left),
    });
  }
};

export const createBaseShadow = () => ({
  shadowColor: "#000",
  shadowRadius: 2,
  shadowOpacity: 0.15,
});

export const createMenuShadow = () => ({
  shadowColor: Theme.menuShadowColor,
  shadowOffset: { width: 1, height: 1 },
  shadowOpacity: 0.5,
  shadowRadius: 2,
});
