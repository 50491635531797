import { getI18nMap } from "@utils/globalUtils";
import React from "react";
import { View, Text } from "react-native";
import { Theme, Teaset } from "ui-m/rn";


class ActionSheet extends Teaset.ActionSheet {

  // 容器
  static Item = props => {
    const { style, disabled } = props;

    return (
      <View
        {...props}
        style={[
          {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            overflow: 'hidden',
            paddingHorizontal: 20,
            paddingVertical: 15,
            opacity: disabled ? Theme.asItemDisabledOpacity : 1,
          },
          style,
        ]}
      />
    );
  }

  // 文字
  static ItemText = props => {
    const { style, disabled } = props;

    return (
      <Text
        {...props}
        style={[
          {
            fontSize: Theme.asItemFontSize,
            textAlign: Theme.asItemTitleAlign,
            color: disabled ? "#A0A0A0" : Theme.asItemTitleColor,
            opacity: disabled ? Theme.asItemDisabledOpacity : 1,
          },
          style,
        ]}
      />
    );
  }

  // 文字+容器
  static Text = props => {
    const Item = this.Item;
    const ItemText = this.ItemText;

    return (
      <Item>
        <ItemText {...props} />
      </Item>
    );
  }

  static createItem = (title = "", onPress = () => { }, itemProps = {}) => ({
    title,
    onPress,
    ...itemProps,
  })

  static createCancelItem = () => this.createItem(getI18nMap()?.["cmn.btn.cancel"])

  static show = (items, cancelItem = this.createCancelItem(), options = {}) => {
    return super.show(items.filter(i => i), cancelItem, options);
  }

}

export default ActionSheet;
