// 用于跨作用域进行页面导航
import React from 'react';
import async from 'crow/utils/async';
import { CommonActions, StackActions } from '@react-navigation/native';
import { preloadScreenOrStack, findBestMatchStackByScreen, findFirstRootStackByScreenIfNotRootScreen } from '../router';

let _isReady, _navigationReadyResolve;

const _navigationReadyPromise = new Promise(resolve => {
  _navigationReadyResolve = resolve;
});

export async function _setNavigationReady() {
  // 绕过 react-navigation 的 bug（onReady的时机不靠谱）
  await async.waitUntil(() => {
    return !!navigationRef.current?.getCurrentRoute();
  });
  _isReady = true;
  _navigationReadyResolve();
}

export const navigationRef = React.createRef();

export async function getNavigation() {
  if (_isReady) {
    return navigationRef.current;
  }
  return _navigationReadyPromise.then(() => {
    return navigationRef.current;
  });
}


let lastRootState, lastCurrentRoute;

export function _setLastRootState(val) {
  if (val) {
    lastRootState = val;
  }
}

export function _setLastCurrentRoute(val) {
  if (val) {
    lastCurrentRoute = val;
  }
}

export function getLastRootState() {
  return lastRootState;
}

export function getLastCurrentRoute() {
  return lastCurrentRoute;
}


// 入参1. navigate(screenName, screenParams?)
// 入参2. navigate(stackName, { screen: screenName, params: screenParams }?)
export async function navigate(...params) {
  const navigation = await getNavigation();
  const screenOrStackName = params[0];

  // 前往 StackA 下的 ScreenB 方式
  // 已在 StackA 中: navigate("ScreenB", { })
  // 不在 StackA 中: navigate("StackA", { screen: "ScreenB", params: { } })

  // 不在 StackA 中时, 去往 StackA 下的 ScreenB 页面, 只能用 入参2, 过于麻烦, 此处兼容 入参1
  const stack = findBestMatchStackByScreen(screenOrStackName);
  if (stack) {
    await preloadScreenOrStack(stack.name);
    return navigation.navigate(stack.name, { screen: screenOrStackName, params: params[1] });
  }

  await preloadScreenOrStack(screenOrStackName);
  return navigation.navigate(...params);
}

// 重置整个路由, 并前往指定页面, 入参同 navigate
export async function rootNavigate(...params) {
  const navigation = await getNavigation();
  const screenOrStackName = params[0];

  const stack = findFirstRootStackByScreenIfNotRootScreen(screenOrStackName);
  if (stack) {
    await preloadScreenOrStack(stack.name);
    return navigateReset({
      index: 0,
      routes: [{ name: stack.name, params: { screen: screenOrStackName, params: params[1] } }],
    });
  }

  await preloadScreenOrStack(screenOrStackName);
  return navigateReset({
    index: 0,
    routes: [{ name: screenOrStackName, params: params[1] }],
  });
}

export async function navigateReplace(...params) {
  const navigation = await getNavigation();
  await preloadScreenOrStack(params[0]);
  return (
    navigation.dispatch(
      StackActions.replace(...params)
    )
  );
}

export async function navigateBack() {
  const navigation = await getNavigation();
  return navigation.goBack();
}

// CommonActions.reset({ index: number, routes: route[] })
// route: {
//   name: screenName
//   params: screenParams
// } | {
//   name: stackName
//   params: { screen: screenName, params: screenParams }
// }
export async function navigateReset(param) {
  const navigation = await getNavigation();
  await Promise.all(param.routes.map(route => preloadScreenOrStack(route.name)));
  return (
    navigation.dispatch(
      CommonActions.reset(param)
    )
  );
}

export const createNavigateShortcut = nameOrRoutes => {
  if (Array.isArray(nameOrRoutes)) {
    return encodeURIComponent(JSON.stringify(nameOrRoutes));
  } else if (typeof nameOrRoutes === "string") {
    return nameOrRoutes; // 字符串就不需要 JSON.stringify
  } else {
    return "";
  }
};

export const parseNavigateShortcut = shortcut => {
  if (typeof shortcut !== "string") {
    return;
  }
  let routes;
  try {
    // routes
    routes = JSON.parse(shortcut);
    if (Array.isArray(routes)) {
      return routes;
    }
  } catch (error) {
    // navigateName
    return [{ name: shortcut }];
  }
};
