import resources from './resources';

export default [
  {
    key: 'toastSucceed',
    source: require('../assets/toast/succeed.png'),
    width: 44,
    height: 44,
  },
  {
    key: 'toastFailed',
    source: require('../assets/toast/failure.png'),
    width: 44,
    height: 44,
  },
  {
    key: 'cellCopy',
    source: resources.cellCopy,
    width: 20,
    height: 20,
  },
  {
    key: "cellEdit",
    source: resources.cellEdit,
    width: 20,
    height: 20,
  },
  {
    key: "loadingMini",
    source: resources.spinnerMini,
    width: 15,
    height: 15,
  },
];
