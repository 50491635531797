import React, { useCallback, useRef } from "react";
import { StyleSheet } from "react-native";
import Touchable from "./Touchable";

const TouchableWithPosition = props => {
  const { onPress, onPressDisabled, disabled, children, ...rest } = props;
  const ref = useRef(null);

  const callWithPosition = useCallback(fn => {
    typeof fn === "function" && ref.current.measureInWindow(
      (x, y, width, height) => fn({ x, y, width, height })
    );
  }, []);

  return (
    <Touchable
      ref={ref}
      onPress={() => callWithPosition(onPress)}
      disabled={disabled}
      {...rest}
    >
      {children}
      {
        !!disabled && typeof onPressDisabled === "function" && (
          <Touchable
            style={{
              ...StyleSheet.absoluteFillObject,
              opacity: 0,
            }}
            activeOpacity={0}
            onPress={() => callWithPosition(onPressDisabled)}
          />
        )
      }
    </Touchable>
  );
};

export default TouchableWithPosition;
