import * as shippingServiceProviderService from '../services/shippingServiceProvider';
import { handleRequestError, createReactiveModelWithRESTfulAPI } from '@utils';

// 非典型列表封装
export default createReactiveModelWithRESTfulAPI({
  namespace: 'shippingServiceProvider',
  service: shippingServiceProviderService,
  handlerRequestErrorByDefaultInModel: res => handleRequestError(res),
  state: {

  },
  initialPagination: {
    pageSize: 1000,
  },
  computed: {
    enabledShippingServiceProviderList: state => {
      return state.resultSet.filter(i => i.enabled && i.status === "available");
    },
  },
  reducers: {
    updateListItem(state, { payload }) {
      const index = state.resultSet.findIndex(i => i.id === payload.id);
      if (index === -1) {
        return state;
      }
      const resultSet = [...state.resultSet];
      resultSet[index] = {
        ...resultSet[index],
        ...payload,
      };

      return ({ ...state, resultSet });
    },
  },
  effects: {
    // 服务商管理
    *initAuthProvider({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.initAuthProvider, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *authProvider({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.authProvider, payload);

      // if (!res.success) {
      //   handleRequestError(res);
      // }

      return res;
    },
    *getSingle({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.getSingle, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *patchSingle({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.patchSingle, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *getAllShippingMethods({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.getAllShippingMethods, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    // 运单相关
    *getShippingOptions({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.getShippingOptions, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *getAvailableShippingMethods({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.getAvailableShippingMethods, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *createShippingLabel({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.createShippingLabel, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *voidTrackingNumber({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.voidTrackingNumber, payload);

      // if (!res.success) {
      //   handleRequestError(res);
      // }

      return res;
    },
  },
});
