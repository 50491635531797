import React from 'react';
import { Image, Platform, StatusBar } from 'react-native';
import { Teaset } from 'ui-m/rn';
const { Menu: TMenu } = Teaset;

const isStatusBarTranslucent = (Platform.OS === 'ios' || Platform.OS === 'android' && Platform.Version > 20);

const Menu = {
  ...TMenu,
  show(){
    const args = [].slice.call(arguments, 0);
    // Android StatusBar穿透情况下，y位置往上偏移。 默认都是穿透，全局补偿一个高度
    args[0] = Platform.OS == 'android' && isStatusBarTranslucent ? {...args[0], y: args[0].y + StatusBar.currentHeight || 0} : args[0];
    if (Array.isArray(args[1])) {
      args[1] = args[1].map(item=>{
        const icon = item.icon;
        // 封装 icon 样式
        if (icon && !React.isValidElement(icon)) {
          item.icon = <Image source={icon} style={{height: 20, width: 20, marginRight: 10}} />;
        }
        // F44336
        return item;
      });
      if (!args[2]) {
        args[2] = {};
      }
      args[2].hideWhenUnderViewMove = true;
      args[2].overlayPointerEvents = 'box-none';
    }
    TMenu.show.apply(TMenu, args);
  },
};

export default Menu;
