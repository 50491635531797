import React, { useRef, useEffect } from "react";
import { Animated, Easing } from "react-native";

const LoopRotateView = props => {
  const { style, children, duration = 670, ...rest } = props;
  const deg = useRef(new Animated.Value(0)).current;
  const easing = Easing.linear;

  useEffect(() => {
    Animated.loop(
      Animated.timing(deg, {
        toValue: 1,
        duration,
        easing,
        useNativeDriver: false,
      })
    ).start();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Animated.View
      style={[style, {
        transform: [{
          rotate: deg.interpolate({
            inputRange: [0, 1],
            outputRange: ['0deg', '360deg'],
          }),
        }],
      }]}
      {...rest}
    >
      {children}
    </Animated.View>
  );
};

export default LoopRotateView;
