import React from 'react';
import { View } from 'react-native';

const SpaceBetweenView = props => {
  const { style, left, right, gap = 10, maxWidth = "70%" } = props;

  return (
    <View style={[{ flexDirection: "row", alignItems: "stretch", justifyContent: "space-between" }, style]}>
      <View style={{ maxWidth, flexShrink: 1 }}>{left}</View>
      <View style={{ width: gap, flexShrink: 0 }} />
      <View style={{ maxWidth, flexShrink: 1 }}>{right}</View>
    </View>
  );
};

export default SpaceBetweenView;
